<template>
    <div class="global-app-wrapper">
        <div class="app-external-wrapper uk-animation-fade" dir="rtl" style="direction: rtl; z-index: 2;">

            <saffron
                    class="app-external-wrapper uk-animation-fade"
                    :use-meta="true" >

                    <router-view v-slot="{ Component }" >
                        <keep-alive :include="['project-index']">
                            <component :is="Component"  uk-scrollspy="cls: uk-animation-fade;"/>
                        </keep-alive>
                    </router-view>

            </saffron>
        </div>


    </div>

</template>

<script>
  import SaffronComponent from '@/client/components/Saffron.vue';

  export default {
    components: {
        Saffron: SaffronComponent
    },
    props : {
      'layout' : {
        type: String,
        default: 'default'
      },
      'forcedLayout' : {
        default: false,
        validator: (value) => (typeof value === 'string' || value === false)
      }
    },
    data() {
      return {
          testData : '123 test data',

      }
    },
      methods: {

      },
    computed : {

    },
    mounted () {
        utilities.wait(1000).then(async () => {
         //   console.log('start call');
         //   await this.asyncOps.asyncCall('test', {}, {method: 'post'});
        //    console.log('end call');
        });
    },
    created () {
        // TODO: pass this via this.$s
        //  this.$store.commit('ui/showGlobalSpinner') ;
        //  this.$store.commit('ui/showGlobalSpinner', true) ;
        //     this.$store.commit('ui/showGlobalSpinner', false) ;
        //     this.$store.commit('ui/hideGlobalSpinner') ;


    },

  }
</script>
<style lang="scss">
.global-app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100vh;
    min-height: 100vh;
}

.change-log-wrap {
    margin-top: auto;
}


</style>
